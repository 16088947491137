function setupCalendarDialog(calLink) {
    const theDialog = document.getElementById('calendar-dialog');

    if (!theDialog || !calLink) {
        return;
    }

    calLink.addEventListener('click', (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        if (theDialog.open) {
            theDialog.close();
        } else {
            theDialog.showModal();
        }
    });

    const closer = theDialog.querySelector('.calendar-dialog-closer');

    if (closer) {
        closer.addEventListener('click', () => {
            theDialog.close();
        });
    }

    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        const copyButton = document.getElementById('copy-to-clip');

        if (copyButton) {
            copyButton.classList.add('enabled');

            copyButton.addEventListener('click', (evt) => {
                evt.preventDefault();
                evt.stopPropagation();

                const link = theDialog.querySelector('a');

                navigator.clipboard.writeText(link.href);

                copyButton.textContent = '✅ copied';
                copyButton.disabled = true;
            });
        }
    }

    const displayLink = theDialog.querySelector('.cal-url-display');

    if (displayLink) {
        const url = new URL(displayLink.text);

        const prefix = `${url.protocol}//${url.host}`;
        const parts = [];
        const remainder = url.pathname.substring(1);

        const components = remainder.split('/');

        components.forEach((component) => {
            parts.push(`/${component}`);
        });

        displayLink.textContent = prefix;

        parts.forEach((part) => {
            displayLink.appendChild(document.createElement('wbr'));
            displayLink.appendChild(document.createTextNode(part));
        });
    }
}

setupCalendarDialog(document.querySelector('.artist-calendar-link'));
